<script setup lang="ts">
import { useStorage } from '@vueuse/core'
const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const { t } = useI18n()
const viewport = useViewport()
const isMobile = computed(() => viewport.isLessThan('md'))
const toLocalePath = useLocalePath()

const { listCompanyVerificationsOnce } = useCompanyVerification()
const { getCompany } = useCompany()
// Get the managing company of this user
const { listCompanyManager } = useCompanyManager()
const managerResult = await listCompanyManager({
  page: 1,
  page_size: 10,
  order_by: '-created_date'
})

const showVerificationReminder = ref<boolean>(false)
const hasHistoricalApprovedVerifications = ref<boolean>(false)

if (managerResult && (managerResult?.data?.value?.count || 0) > 0) {
  const companyIdSlug = managerResult.data?.value?.data[0].company.id_slug
  const companyVerificationResult = await listCompanyVerificationsOnce({
    company_id_slug: companyIdSlug
  })
  const companyResult = await getCompany(companyIdSlug as string)
  const hasValidVerification = Boolean(!companyVerificationResult?.data.find((v) => v.selected))
  showVerificationReminder.value =
    Boolean(companyResult.admin_part?.need_verification) && !hasValidVerification
  hasHistoricalApprovedVerifications.value = Boolean(
    companyVerificationResult?.data.find((v) => v.status === 'APPROVED')
  )
  console.log('showVerificationReminder', showVerificationReminder.value)
  console.log('hasHistoricalApprovedVerifications', hasHistoricalApprovedVerifications.value)
}

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

useHeadSafe({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
    dir: i18nHead.value.htmlAttrs!.dir
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])]
})

const { status: authStatus, data: user } = useAuth()
const links = computed(() => {
  return isJobBoard()
    ? [
        { text: t('jobBoard.navBar.findJobs'), to: '/jobs' },
        { text: t('jobBoard.navBar.newsAndInsight'), to: '/articles' }
      ]
    : []
})

const showBanner = useStorage('showBanner', isJobBoard())

function closeBanner() {
  showBanner.value = false
}
</script>

<template>
  <div>
    <ClientOnly>
      <div v-if="showBanner" class="grid">
        <div class="col-start-1 row-start-1 h-0 min-h-full w-full overflow-hidden">
          <NuxtImg
            :preload="false"
            src="/images/events/salary-survey/bg-img.png"
            class="h-full w-full object-cover"
          />
        </div>
        <div
          class="z-10 col-start-1 row-start-1 ml-auto hidden gap-[1.88rem] py-[1.37rem] pr-[1.88rem] text-primary md:flex md:flex-row"
        >
          <div
            class="hidden cursor-pointer flex-row gap-[0.31rem] rounded-[20.8125rem] border bg-[#FDEB5B] px-[1rem] py-[0.62rem] lg:px-[1.88rem] min-[1170px]:flex"
            @click="
              navigateTo('https://form.jotform.com/240878587065067', {
                open: {
                  target: '_blank'
                }
              })
            "
          >
            <div class="text-h5 font-bold text-primary">
              {{ $t('jobBoard.salarySurveyEvent.bannerButton') }}
            </div>
            <IconsFigmaArrowForwardOutlined
              class="hidden h-[1.25rem] w-[1.25rem] shrink-0 lg:flex"
            />
          </div>
          <IconsFigmaCloseOutlined
            class="w-[1.88rem] cursor-pointer md:w-[2.25rem]"
            @click="closeBanner"
          />
        </div>
        <div
          class="gap col-start-1 row-start-1 mx-auto my-auto hidden w-full flex-col items-center justify-center text-center text-h4 font-bold text-primary md:flex md:flex-row"
        >
          <div class="flex flex-row items-center justify-center gap-[0.62rem]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M15.9625 7.25125L16.3139 8.25013H15.255H9C8.80109 8.25013 8.61032 8.32915 8.46967 8.4698C8.32902 8.61045 8.25 8.80122 8.25 9.00013V15.2551V16.3146L7.25076 15.9625C6.68525 15.7633 6.08021 15.7025 5.48637 15.7854C4.89254 15.8682 4.32722 16.0923 3.83785 16.4387C3.34848 16.7851 2.94931 17.2438 2.67382 17.7764C2.39833 18.3089 2.25456 18.8998 2.25456 19.4994C2.25456 20.099 2.39833 20.6898 2.67382 21.2224C2.94931 21.7549 3.34848 22.2136 3.83785 22.5601C4.32722 22.9065 4.89254 23.1306 5.48637 23.2134C6.08021 23.2963 6.68525 23.2355 7.25076 23.0363L8.25 22.6842V23.7436V30.0001C8.25 30.199 8.32902 30.3898 8.46967 30.5305C8.61032 30.6711 8.80109 30.7501 9 30.7501H30C30.1989 30.7501 30.3897 30.6711 30.5303 30.5305C30.671 30.3898 30.75 30.199 30.75 30.0001V9.00013C30.75 8.80122 30.671 8.61045 30.5303 8.4698C30.3897 8.32915 30.1989 8.25013 30 8.25013H23.745H22.6865L23.0374 7.25146C23.1748 6.86068 23.2499 6.43936 23.25 6.00062C23.2491 5.45519 23.1292 4.91652 22.8987 4.42218C22.6682 3.92765 22.3324 3.48939 21.915 3.13799C21.4976 2.7866 21.0085 2.53053 20.4819 2.38766C19.9553 2.24479 19.4038 2.21857 18.866 2.31081C18.3282 2.40306 17.8171 2.61155 17.3682 2.92175C16.9193 3.23194 16.5435 3.63636 16.267 4.10678C15.9905 4.57719 15.8201 5.10228 15.7675 5.64538C15.7149 6.18848 15.7814 6.73652 15.9625 7.25125Z"
                fill="#FBEA50"
                stroke="#1C1C1C"
                stroke-width="1.5"
              />
            </svg>
            <div>
              <div
                class="inline cursor-pointer"
                @click="navigateTo(toLocalePath('/hk-events/hk-salary-survey'))"
              >
                {{ $t('jobBoard.salarySurveyEvent.page1title') }}
              </div>
            </div>
            <div class="rotate-90">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M15.9625 7.25125L16.3139 8.25013H15.255H9C8.80109 8.25013 8.61032 8.32915 8.46967 8.4698C8.32902 8.61045 8.25 8.80122 8.25 9.00013V15.2551V16.3146L7.25076 15.9625C6.68525 15.7633 6.08021 15.7025 5.48637 15.7854C4.89254 15.8682 4.32722 16.0923 3.83785 16.4387C3.34848 16.7851 2.94931 17.2438 2.67382 17.7764C2.39833 18.3089 2.25456 18.8998 2.25456 19.4994C2.25456 20.099 2.39833 20.6898 2.67382 21.2224C2.94931 21.7549 3.34848 22.2136 3.83785 22.5601C4.32722 22.9065 4.89254 23.1306 5.48637 23.2134C6.08021 23.2963 6.68525 23.2355 7.25076 23.0363L8.25 22.6842V23.7436V30.0001C8.25 30.199 8.32902 30.3898 8.46967 30.5305C8.61032 30.6711 8.80109 30.7501 9 30.7501H30C30.1989 30.7501 30.3897 30.6711 30.5303 30.5305C30.671 30.3898 30.75 30.199 30.75 30.0001V9.00013C30.75 8.80122 30.671 8.61045 30.5303 8.4698C30.3897 8.32915 30.1989 8.25013 30 8.25013H23.745H22.6865L23.0374 7.25146C23.1748 6.86068 23.2499 6.43936 23.25 6.00062C23.2491 5.45519 23.1292 4.91652 22.8987 4.42218C22.6682 3.92765 22.3324 3.48939 21.915 3.13799C21.4976 2.7866 21.0085 2.53053 20.4819 2.38766C19.9553 2.24479 19.4038 2.21857 18.866 2.31081C18.3282 2.40306 17.8171 2.61155 17.3682 2.92175C16.9193 3.23194 16.5435 3.63636 16.267 4.10678C15.9905 4.57719 15.8201 5.10228 15.7675 5.64538C15.7149 6.18848 15.7814 6.73652 15.9625 7.25125Z"
                  fill="#FBEA50"
                  stroke="#1C1C1C"
                  stroke-width="1.5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="col-start-1 row-start-1 mx-auto flex w-[90vw] flex-row justify-between gap-[0.88rem] text-primary md:hidden"
        >
          <div
            class="flex flex-row items-center gap-[0.3rem] py-[1.38rem] sm:gap-[0.91rem]"
            @click="navigateTo(toLocalePath('/hk-events/hk-salary-survey'))"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M15.9625 7.25125L16.3139 8.25013H15.255H9C8.80109 8.25013 8.61032 8.32915 8.46967 8.4698C8.32902 8.61045 8.25 8.80122 8.25 9.00013V15.2551V16.3146L7.25076 15.9625C6.68525 15.7633 6.08021 15.7025 5.48637 15.7854C4.89254 15.8682 4.32722 16.0923 3.83785 16.4387C3.34848 16.7851 2.94931 17.2438 2.67382 17.7764C2.39833 18.3089 2.25456 18.8998 2.25456 19.4994C2.25456 20.099 2.39833 20.6898 2.67382 21.2224C2.94931 21.7549 3.34848 22.2136 3.83785 22.5601C4.32722 22.9065 4.89254 23.1306 5.48637 23.2134C6.08021 23.2963 6.68525 23.2355 7.25076 23.0363L8.25 22.6842V23.7436V30.0001C8.25 30.199 8.32902 30.3898 8.46967 30.5305C8.61032 30.6711 8.80109 30.7501 9 30.7501H30C30.1989 30.7501 30.3897 30.6711 30.5303 30.5305C30.671 30.3898 30.75 30.199 30.75 30.0001V9.00013C30.75 8.80122 30.671 8.61045 30.5303 8.4698C30.3897 8.32915 30.1989 8.25013 30 8.25013H23.745H22.6865L23.0374 7.25146C23.1748 6.86068 23.2499 6.43936 23.25 6.00062C23.2491 5.45519 23.1292 4.91652 22.8987 4.42218C22.6682 3.92765 22.3324 3.48939 21.915 3.13799C21.4976 2.7866 21.0085 2.53053 20.4819 2.38766C19.9553 2.24479 19.4038 2.21857 18.866 2.31081C18.3282 2.40306 17.8171 2.61155 17.3682 2.92175C16.9193 3.23194 16.5435 3.63636 16.267 4.10678C15.9905 4.57719 15.8201 5.10228 15.7675 5.64538C15.7149 6.18848 15.7814 6.73652 15.9625 7.25125Z"
                fill="#FBEA50"
                stroke="#1C1C1C"
                stroke-width="1.5"
              />
            </svg>
            <div class="mx-auto flex flex-wrap text-center text-h6 font-bold">
              {{ $t('jobBoard.salarySurveyEvent.page1title') }}
            </div>
            <div class="rotate-90">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M15.9625 7.25125L16.3139 8.25013H15.255H9C8.80109 8.25013 8.61032 8.32915 8.46967 8.4698C8.32902 8.61045 8.25 8.80122 8.25 9.00013V15.2551V16.3146L7.25076 15.9625C6.68525 15.7633 6.08021 15.7025 5.48637 15.7854C4.89254 15.8682 4.32722 16.0923 3.83785 16.4387C3.34848 16.7851 2.94931 17.2438 2.67382 17.7764C2.39833 18.3089 2.25456 18.8998 2.25456 19.4994C2.25456 20.099 2.39833 20.6898 2.67382 21.2224C2.94931 21.7549 3.34848 22.2136 3.83785 22.5601C4.32722 22.9065 4.89254 23.1306 5.48637 23.2134C6.08021 23.2963 6.68525 23.2355 7.25076 23.0363L8.25 22.6842V23.7436V30.0001C8.25 30.199 8.32902 30.3898 8.46967 30.5305C8.61032 30.6711 8.80109 30.7501 9 30.7501H30C30.1989 30.7501 30.3897 30.6711 30.5303 30.5305C30.671 30.3898 30.75 30.199 30.75 30.0001V9.00013C30.75 8.80122 30.671 8.61045 30.5303 8.4698C30.3897 8.32915 30.1989 8.25013 30 8.25013H23.745H22.6865L23.0374 7.25146C23.1748 6.86068 23.2499 6.43936 23.25 6.00062C23.2491 5.45519 23.1292 4.91652 22.8987 4.42218C22.6682 3.92765 22.3324 3.48939 21.915 3.13799C21.4976 2.7866 21.0085 2.53053 20.4819 2.38766C19.9553 2.24479 19.4038 2.21857 18.866 2.31081C18.3282 2.40306 17.8171 2.61155 17.3682 2.92175C16.9193 3.23194 16.5435 3.63636 16.267 4.10678C15.9905 4.57719 15.8201 5.10228 15.7675 5.64538C15.7149 6.18848 15.7814 6.73652 15.9625 7.25125Z"
                  fill="#FBEA50"
                  stroke="#1C1C1C"
                  stroke-width="1.5"
                />
              </svg>
            </div>
          </div>

          <div>
            <IconsFigmaCloseOutlined class="w-[1.5rem] cursor-pointer" @click="closeBanner" />
          </div>
        </div>
      </div>
    </ClientOnly>
    <Navbar :links="links" :active-link="route.path">
      <template #company-logo>
        <NuxtLinkLocale v-if="isJobBoard()" to="/">
          <div class="w-[7.02331rem]">
            <nuxt-picture
              format="webp"
              src="/images/tja_logo.png"
              alt="TechJobAsia Logo"
              width="220"
              height="59"
              :preload="true"
            />
          </div>
        </NuxtLinkLocale>
        <NuxtLink v-else :to="runtimeConfig.public.tlUrl">
          <div class="w-[7.02331rem]">
            <nuxt-picture
              format="webp"
              src="/images/talentlabs-logo.svg"
              alt="TalentLabs Logo"
              width="300"
              height="59"
              :preload="true"
            />
          </div>
        </NuxtLink>
      </template>
      <template #menu>
        <div v-if="authStatus === 'authenticated'" class="flex flex-row items-center gap-[1.25rem]">
          <NavAvatarDropDown :user="user" />
          <div v-if="isJobBoard()" class="hidden lg:flex">
            <ButtonBlackButton
              v-if="getPrimaryUserRole(user) === 'employer'"
              variant="outlined"
              size="small"
              :text="$t('jobBoard.navBar.forRecruiter')"
              :to="toLocalePath('/hradmin')"
              mode="navigation"
            />
          </div>
          <div v-if="isJobBoard()" class="hidden lg:flex">
            <ButtonBlackButton
              v-if="getPrimaryUserRole(user) === 'admin'"
              variant="outlined"
              size="small"
              :text="$t('jobBoard.navBar.forAdmin')"
              :to="toLocalePath('/hradmin')"
              mode="navigation"
            />
          </div>
        </div>
        <div
          v-else-if="authStatus === 'unauthenticated'"
          class="flex flex-row items-center gap-[0.62rem]"
        >
          <div class="hidden lg:flex">
            <ButtonPurpleButton
              :text="$t('jobBoard.navBar.getStarted')"
              size="small"
              mode="navigation"
              :to="toLocalePath('/auth/login?role=candidate')"
            >
              <template #right-icon>
                <IconsFigmaArrowForwardOutlined />
              </template>
            </ButtonPurpleButton>
          </div>
          <div class="lg:hidden">
            <ButtonPurpleButton
              size="small"
              :text="$t('jobBoard.navBar.getStartedShort')"
              mode="navigation"
              :to="toLocalePath('/auth/login?role=candidate')"
            />
          </div>
          <div class="hidden lg:flex">
            <ButtonBlackButton
              variant="outlined"
              size="small"
              :text="
                isMobile ? $t('jobBoard.navBar.recruiter') : $t('jobBoard.navBar.forRecruiter')
              "
              :to="toLocalePath('/auth/login?role=employer')"
              mode="navigation"
            />
          </div>
        </div>
      </template>
    </Navbar>
    <div
      v-if="user?.secret_part && user.secret_part.anonymous_signup_stage !== 'DONE'"
      class="bg-primary p-[0.88rem] text-white"
    >
      <div class="container">
        {{ $t('jobBoard.navBar.haventSetupPassword') }}
        <NuxtLinkLocale to="auth-reset-request" class="text-blue-40 hover:underline">{{
          $t('jobBoard.navBar.clickToSetupPassword')
        }}</NuxtLinkLocale>
      </div>
    </div>
    <div v-if="user && showVerificationReminder" class="bg-red-40 p-[0.88rem] text-white">
      <div class="container">
        <span v-if="hasHistoricalApprovedVerifications">
          You cannot post jobs until your business registration is renewed.
        </span>
        <span v-else
          >You cannot post jobs until you have verified your business registration.
        </span>
        <NuxtLinkLocale
          :to="{
            name: 'hradmin',
            query: { tab: 'companyVerifications' }
          }"
          ><span class="font-medium underline">
            Go renew business registration status.
          </span></NuxtLinkLocale
        >
      </div>
    </div>
    <slot />
    <LazyFooter v-if="isJobBoard()" />
  </div>
</template>

<style>
/* For view transition API */
/* .search-group {
  view-transition-name: search-group;
} */

/* .main-panel {
  view-transition-name: main-panel;
} */

/* .breadcrumb {
  view-transition-name: breadcrumb;
} */

/* .article-cover-image {
  view-transition-name: article-cover-image;
} */

/* .footer {
  view-transition-name: footer;
} */

@media (prefers-reduced-motion) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}

::view-transition-group(*),
::view-transition-old(*),
::view-transition-new(*) {
  animation-duration: 0.2s;
}

::view-transition-old(article-cover-image),
::view-transition-new(article-cover-image) {
  /* Make the height the same as the group,
  meaning the view size might not match its aspect-ratio. */
  height: 100%;
  /* Clip any overflow of the view */
  overflow: clip;
}

/* The old view is the thumbnail */
::view-transition-old(article-cover-image) {
  /* Maintain the aspect ratio of the view,
  by shrinking it to fit within the bounds of the element */
  object-fit: contain;
}

/* The new view is the full image */
::view-transition-new(article-cover-image) {
  /* Maintain the aspect ratio of the view,
  by growing it to cover the bounds of the element */
  object-fit: cover;
}
</style>
